

























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { OfficeModel } from "@/models/Employer";
import BaseLink from "@/components/common/BaseLink.vue";
import ApplyCardWrapper from "@/views/shared/ApplyCardWrapper.vue";

@Component({
  name: "CompanyApplyCard",
  components: { BaseLink, ApplyCardWrapper },
})
export default class extends Vue {
  @Prop({ required: true })
  public readonly office!: OfficeModel;

  get avatar() {
    const avatarUrl = this.office.avatar?.resizedUrl;
    return avatarUrl ? avatarUrl : require("@/assets/no-avatar.svg");
  }

  visitCompanyWebsite() {
    const url = this.office.profileLink;
    window.location.href = url;
  }
}
