





























































import Vue from "vue";
import Component from "vue-class-component";
import { namespace } from "vuex-class";
import { IUpdateHeaderFooterData } from "@/store/modules/headerFooterInfo";
import { MediaModel, PedigreeCompanyModel, SkillModel } from "@/models/common";
import { EmployerPageModel } from "@/models/Page";
import { PageService } from "@/services/Public";
import { PeriodSectionList } from "@/components/lists";
import ChipsList from "@/components/lists/ChipsList/index.vue";
import MediaSectionList from "@/components/lists/MediaSectionList/index.vue";
import TextViewer from "@/components/common/TextViewer.vue";
import PageContainer from "@/views/shared/PageContainer.vue";
import PageContainerHeader from "@/views/shared/PageContainerHeader.vue";
import LocationMarker from "@/views/shared/LocationMarker.vue";
import SocialLinksList from "@/views/shared/SocialLinksList.vue";
import JobOpportunities from "@/views/shared/JobOpportunities.vue";
import SimilarOpportunities from "@/views/shared/SimilarOpportunities.vue";
import RecruiterApplyCard from "./RecruiterApplyCard.vue";

const HeaderFooterInfo = namespace("headerFooterInfo");

@Component({
  name: "RecruiterPage",
  components: {
    PageContainer,
    PageContainerHeader,
    LocationMarker,
    SocialLinksList,
    RecruiterApplyCard,
    TextViewer,
    MediaSectionList,
    ChipsList,
    PeriodSectionList,
    JobOpportunities,
    SimilarOpportunities,
  },
})
export default class extends Vue {
  @HeaderFooterInfo.Action("updateData")
  updateHeaderFooterInfo!: IUpdateHeaderFooterData;

  private recruiterData: EmployerPageModel | null = null;

  private loading = false;

  isAuthenticated(isCandidate = true) {
    return isCandidate
      ? this.recruiterData?.isCandidate && this.recruiterData.isAuthenticated
      : this.recruiterData?.isAuthenticated;
  }

  async mounted() {
    // this.updateHeaderFooterInfo({
    //   // TODO: is it need?
    //   contactAddress: "555 Address St., Suite BCity, StateZipcode",
    //   logoImageUrl: this.recruiterData?.employer.information.avatar.fileUrl,
    // });

    try {
      this.loading = true;
      this.updateHeaderFooterInfo();

      const candidateUrl = this.$router.currentRoute.fullPath;
      this.recruiterData = await PageService.getProfile(candidateUrl);
    } catch (e) {
      console.log("Recruiter page fetch error: ", e);
    } finally {
      this.loading = false;
    }
  }

  get openOpportunities() {
    const items = this.recruiterData?.employer.openOpportunities;

    if (!items) return [];
    return items;
  }

  get cover() {
    return this.recruiterData?.employer.cover
      ? this.recruiterData.employer.cover.originalUrl
      : "";
  }

  get recruiterName(): string {
    if (!this.recruiterData) return "";
    const info = this.recruiterData.employer.information;
    return `${info?.firstName} ${info.lastName}`;
  }

  get skills() {
    const items = this.recruiterData?.employer.pedigree.skills;

    if (!items) return [];
    return items.map((x: SkillModel) => ({ text: x.title }));
  }

  get media() {
    const items = this.recruiterData?.employer.media;

    if (!items) return [];
    return items.map((x: MediaModel) => ({
      title: x.title,
      src: x.fileUrl,
      previewSrc: x.previewUrl,
      type: x.fileType,
      extension: x.extension,
    }));
  }

  get experience() {
    const items = this.recruiterData?.employer?.pedigree?.companies;

    if (!items) return [];
    return items.map((x: PedigreeCompanyModel) => ({
      title: x.title,
      subtitle: x.companyName,
      period: x.isCurrentJob ? "Current" : "",
    }));
  }

  // get isNotMoreJobs(): boolean {
  //   const jobCount: number = this.recruiterData.employerJobs.featuredJobsCount;
  //   const vacanciesLength: number =
  //     this.recruiterData.employerJobs.vacancies.length + 1;

  //   return vacanciesLength <= jobCount;
  // }

  get avatar() {
    const avatarUrl =
      this.recruiterData?.employer.information.avatar?.resizedUrl;
    return avatarUrl ? avatarUrl : require("@/assets/no-avatar.svg");
  }
}
